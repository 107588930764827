/* eslint-disable react/prop-types */
import React from "react";

export default function Sun({ className, color }) {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 7.5C10 8.88071 8.88071 10 7.5 10C6.11929 10 5 8.88071 5 7.5C5 6.11929 6.11929 5 7.5 5C8.88071 5 10 6.11929 10 7.5Z"
        fill={color}
      />
      <path
        d="M7 0.5C7 0.223858 7.22386 0 7.5 0C7.77614 0 8 0.223858 8 0.5V3.5C8 3.77614 7.77614 4 7.5 4C7.22386 4 7 3.77614 7 3.5V0.5Z"
        fill={color}
      />
      <path
        d="M11.0608 3.35355C11.256 3.15829 11.5726 3.15829 11.7679 3.35355C11.9631 3.54882 11.9631 3.8654 11.7679 4.06066L11.0608 4.76777C10.8655 4.96303 10.5489 4.96303 10.3536 4.76777C10.1584 4.5725 10.1584 4.25592 10.3536 4.06066L11.0608 3.35355Z"
        fill={color}
      />
      <path
        d="M0.5 8C0.223858 8 1.20706e-08 7.77614 0 7.5C-1.20706e-08 7.22386 0.223858 7 0.5 7H3.5C3.77614 7 4 7.22386 4 7.5C4 7.77614 3.77614 8 3.5 8H0.5Z"
        fill={color}
      />
      <path
        d="M3.35355 4.06066C3.15829 3.8654 3.15829 3.54882 3.35355 3.35355C3.54882 3.15829 3.8654 3.15829 4.06066 3.35355L4.76777 4.06066C4.96303 4.25592 4.96303 4.57251 4.76777 4.76777C4.5725 4.96303 4.25592 4.96303 4.06066 4.76777L3.35355 4.06066Z"
        fill={color}
      />
      <path
        d="M7 11.5C7 11.2239 7.22386 11 7.5 11C7.77614 11 8 11.2239 8 11.5V14.5C8 14.7761 7.77614 15 7.5 15C7.22386 15 7 14.7761 7 14.5V11.5Z"
        fill={color}
      />
      <path
        d="M4.06075 10.3536C4.25602 10.1583 4.5726 10.1583 4.76786 10.3536C4.96312 10.5488 4.96312 10.8654 4.76786 11.0607L4.06075 11.7678C3.86549 11.963 3.54891 11.963 3.35365 11.7678C3.15838 11.5725 3.15838 11.2559 3.35365 11.0607L4.06075 10.3536Z"
        fill={color}
      />
      <path
        d="M11.5 8C11.2239 8 11 7.77614 11 7.5C11 7.22386 11.2239 7 11.5 7H14.5C14.7761 7 15 7.22386 15 7.5C15 7.77614 14.7761 8 14.5 8H11.5Z"
        fill={color}
      />
      <path
        d="M10.3536 11.0607C10.1583 10.8654 10.1583 10.5488 10.3536 10.3536C10.5488 10.1583 10.8654 10.1583 11.0607 10.3536L11.7678 11.0607C11.963 11.2559 11.963 11.5725 11.7678 11.7678C11.5725 11.963 11.2559 11.963 11.0607 11.7678L10.3536 11.0607Z"
        fill={color}
      />
    </svg>
  );
}
